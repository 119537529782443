<template>
  <div class="row">
    <div class="col-12 d-flex justify-content-center mb-1">
      <img :src="thirdParty.logo_url" width="150" />
    </div>
    <div class="col-12">
      <b-form @submit.prevent="saveData">
          <b-form-group v-for="(field, fk) in thirdParty.fields" :key="`field_${fk}`">
            <label class="mb-0">{{ field.label }}</label>
            <template v-if="field.type === 'text'">
              <b-input v-model="form[fk]" :placeholder="field.placeholder" :required="field.is_required" />
            </template>
            <template v-else-if="field.type === 'email'">
              <b-input v-model="form[fk]" :placeholder="field.placeholder" :required="field.is_required" type="email" />
            </template>
            <template v-else-if="field.type === 'password'">
              <b-input v-model="form[fk]" :placeholder="field.placeholder" :required="field.is_required" type="password" />
            </template>
          </b-form-group>
          <div class="row d-flex justify-content-end mt-3">
              <div class="col-12">
                  <b-form-checkbox
                      id="checkbox-1"
                      v-model="form.is_default"
                      name="checkbox-1"
                      :value="1"
                      :key="`ck_${thirdParty.unique_key}`"
                      :unchecked-value="0"
                  >
                      Use Default for <strong class="text-info">{{ service.label }} service</strong>
                  </b-form-checkbox>
              </div>
              <div class="col-12 mt-1">
                  <b-button variant="primary" type="submit">Save</b-button>
              </div>
          </div>
      </b-form>
    </div>
    <div v-if="!isEmpty(thirdParty.info_message)" class="col-12 my-4">
      <div class="alert alert-dark p-2" v-html="thirdParty.info_message" />
    </div>
  </div>
</template>

<script>
import { hideLoader, showLoader } from '@/@core/comp-functions/ui/app';
import { isEmpty } from '@/utilities';
import { mapActions } from 'vuex';
export default {
  name: "ThirpartyConfigForm",
  props: {
    thirdpartyConfig: Object,
    service: Object,
    thirdParty: Object
  },
  data: () => ({
    form: {},
  }),
  methods: {
    isEmpty,
    ...mapActions('thirdpartyConfig', ['saveThirdpartyConfig', 'updateThirdpartyConfig']),
    saveData(){
      const payload = {
        service: this.service.key,
        company_unique_key: this.thirdParty.unique_key,
        ...this.form
      }
      let fnName = this.saveThirdpartyConfig;
      let isNew = true
      if(!isEmpty(this.form.id)){
        fnName = this.updateThirdpartyConfig;
        isNew = false
      }
      showLoader()
      fnName(payload).then(i => {
        if(isNew){
          this.$emit('itemAdded', i)
        }else{
          this.$emit('itemUpdated', i)
        }
        hideLoader()
      }).catch(e => {
        hideLoader()
      });
    },
    initForm(){
      this.form = {}
      if(typeof this.form.id !== typeof undefined){
        delete this.form.id
      }
      if(typeof this.form.is_default !== typeof undefined){
        delete this.form.is_default
      }
      this.form.is_default = 0
      let meta = {}
      if(!isEmpty(this.thirdpartyConfig)){
        this.form.id = this.thirdpartyConfig.id;
        this.form.is_default = this.thirdpartyConfig?.is_default ?? 0;
        if(!isEmpty(this.thirdpartyConfig?.meta)){
          meta = JSON.parse(this.thirdpartyConfig.meta)
        }
        Object.keys(this.thirdParty.fields).forEach(i => {
          this.form[i] = isEmpty(meta[i]) ? null : meta[i]
        })
      }
    }
  },
  mounted(){
    this.initForm()
  },
  watch: {
    thirdpartyConfig: {
      handler(){
        this.initForm()
      },
      deep: true,
    }
  }
}
</script>
<style>
.text-area-22 {
  font-size: 1.2rem !important;
}
</style>